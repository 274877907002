import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import Logout from '@mui/icons-material/Logout';
import PaymentOutlined from '@mui/icons-material/PaymentOutlined';
import { MenuItem, Select, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';
import { NavLink as RouterLink, matchPath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AbilityGuard } from 'src/Guards/abilityGuard/AbilityGuard';
import { UserRole } from 'src/apiClient';
import HiddenWrapper from 'src/components/componentWrappers/HiddenWrapper';
import Logo from 'src/components/logoSign';
import UserInfo from 'src/components/userInfo/UserInfo';
import { ABILITY_TYPES, abilityPages } from 'src/config/ability';
import { env } from 'src/env';
import { ThemeSwitch } from 'src/features/appTheme';
import { useAuth } from 'src/features/authentication';
import pages from 'src/router/routes';
import { useUpdateUserMutation } from 'src/services/restApi/queries/users.service';
import { useUserAuthStore } from 'src/store/userAuth/userStore';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3, 3),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const LogoAnimationBox = styled(Box)(() => ({
  animation: 'logoShowSideBar 1s ease 0s 1 normal forwards',
  '@keyframes logoShowSideBar': {
    '0%': {
      opacity: 0,
      transform: 'rotateY(100deg)',
      transformOrigin: 'left',
    },
    '100%': {
      opacity: 1,
      transform: 'rotateY(0)',
      transformOrigin: 'left',
    },
  },
}));

const CustomListItem = ({
  Icon,
  text,
  open,
  to,
  onClick,
}: {
  Icon: React.ElementType<SvgIconProps>;
  text: string;
  open: boolean;
  to: string;
  onClick: () => void;
}) => {
  return (
    <ListItem component="div" disablePadding sx={{ display: 'block' }}>
      <Tooltip arrow disableHoverListener={open} placement="right" title={text}>
        <NavButton
          sx={{
            justifyContent: open ? 'initial' : 'center',
          }}
          selected={
            !!matchPath(
              {
                path: to,
                end: false,
              },
              window.location.pathname
            )
          }
          component={RouterLink}
          to={to}
          onClick={onClick}
        >
          <NavDivider
            bgcolor={
              matchPath(
                {
                  path: to,
                  end: false,
                },
                window.location.pathname
              )
                ? 'primary.main'
                : 'transparent'
            }
          />
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 1 : 'auto',
              justifyContent: 'center',
            }}
          >
            <Icon color="inherit" fontSize="small" />
          </ListItemIcon>

          <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
        </NavButton>
      </Tooltip>
    </ListItem>
  );
};

const NavButton = styled(ListItemButton)(({ theme }) => ({
  minHeight: 48,
  px: 2.5,
  borderEndEndRadius: 6,
  [`&.${listItemButtonClasses.selected}`]: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.dark,
    },
  },
})) as typeof ListItemButton;

const NavDivider = styled(Box)(() => ({
  left: 0,
  width: '4px',
  height: '24px',
  position: 'absolute',
  borderRadius: '0 8px 8px 0',
})) as typeof Box;

interface Props {
  open: boolean;
  handleDrawerToggle: () => void;
  shouldCloseOnClick?: boolean;
}

export default function SidebarContent({
  open,
  handleDrawerToggle,
  shouldCloseOnClick = false,
}: Props) {
  const { logout } = useAuth();
  const [updateRole] = useUpdateUserMutation();
  const navigate = useNavigate();

  const handleNavButtonClick = () => {
    if (shouldCloseOnClick) {
      handleDrawerToggle();
    }
  };

  const handleLogout = () => {
    try {
      logout();
    } catch (err) {
      toast.error('Could not log out. Please try again.');
    }
  };
  const setRole = useUserAuthStore((state) => state.setUserRole);
  const setDbUser = useUserAuthStore((state) => state.setDbUser);
  const handleRoleChange = async (userRole: UserRole) => {
    // update user in the BE
    const result = await updateRole({
      userId: useUserAuthStore.getState().dbUser?.id ?? 0,
      role: userRole,
    }).unwrap();
    if (result.isSuccess) {
      setRole(userRole);
      if (result.value) {
        setDbUser(result.value);
      } else {
        toast.error('Failed to update user role.');
      }
      //navigate to the dashboard
      navigate(pages.home.path);
      window.location.reload();
    }
  };

  const admin = useUserAuthStore(
    (state) => state.role === UserRole.LS_ADMIN || state.role === UserRole.LS_USER
  );
  const dbUser = useUserAuthStore((state) => state.dbUser);
  const clientId = useUserAuthStore((state) => state.dbUser?.clientId);
  const userRoles: UserRole[] = Object.values(UserRole);

  return (
    <>
      {open && (
        <DrawerHeader id="sidebar-logo">
          <LogoAnimationBox>
            <Logo width={150} />
          </LogoAnimationBox>
        </DrawerHeader>
      )}
      {!open && (
        <DrawerHeader id="sidebar-logo">
          <Box>
            <img
              src={'/static/images/brand/LeasureLogoIcon.svg'}
              alt="Logo"
              height={30}
              width={150}
            />
          </Box>
        </DrawerHeader>
      )}

      <Divider
        textAlign="right"
        id="sidebar-layout-divider"
        sx={{
          alignItems: 'center',
        }}
      >
        <HiddenWrapper mobileOnly>
          <ThemeSwitch />
        </HiddenWrapper>
        {/* <Tooltip
          arrow
          placement="right"
          title={open ? 'Close Sidebar' : 'Open Sidebar'}
        > */}
        <IconButton
          color="secondary"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          size="small"
        >
          {open ? (
            <FirstPageRoundedIcon fontSize="small" color="primary" id="sidebar-layout-close" />
          ) : (
            <LastPageRoundedIcon fontSize="small" color="primary" id="sidebar-layout-open" />
          )}
        </IconButton>
        {/* </Tooltip> */}
      </Divider>

      <Stack direction="column" justifyContent="space-between" sx={{ mt: 1.5, height: '100%' }}>
        <div>
          {!open && <Divider sx={{ my: 1, mx: 1 }} />}

          <AbilityGuard i={ABILITY_TYPES.VISIT} a={abilityPages.HOME}>
            <CustomListItem
              Icon={DashboardOutlinedIcon}
              text="Dashboard"
              open={open}
              to={pages.dashboard.path}
              onClick={handleNavButtonClick}
            />
          </AbilityGuard>

          <AbilityGuard
            i={ABILITY_TYPES.VISIT}
            a={admin ? abilityPages.CLIENT : abilityPages.BUILDINGS}
          >
            <CustomListItem
              Icon={PaymentOutlined}
              text={admin ? 'Claims' : 'Buildings'}
              open={open}
              to={
                admin
                  ? pages.client.path
                  : clientId
                    ? pages.client.buildings.path.replace(':clientId', clientId.toString())
                    : pages.status.status404.path //todo: replace with an unauthorized page
              }
              onClick={handleNavButtonClick}
            />
          </AbilityGuard>

          {/* <CustomListItem
            Icon={PeopleOutline}
            text="Clients"
            open={open}
            to={pages.page1.path}
            onClick={handleNavButtonClick}
          />

          <CustomListItem
            Icon={PushPinOutlined}
            text="Tasks"
            open={open}
            to={pages.page1.path}
            onClick={handleNavButtonClick}
          />

          <CustomListItem
            Icon={SettingsOutlined}
            text="Settings"
            open={open}
            to={pages.page1.path}
            onClick={handleNavButtonClick}
          /> */}
        </div>
        <div>
          <ListItem
            component="div"
            sx={{
              pl: '12px',
            }}
          >
            <UserInfo open={open} />
          </ListItem>
          {open &&
            (env.REACT_APP_DEPLOYMENT_ENV === 'development' ||
              env.REACT_APP_DEPLOYMENT_ENV === 'local') && (
              <ListItem>
                <Select
                  variant="outlined"
                  sx={{
                    width: '100%',
                    fontSize: '10px',
                    '& .MuiSelect-select': {
                      padding: '4PX 10px',
                    },
                  }}
                  value={dbUser?.role.name}
                  onChange={(e) => handleRoleChange(e.target.value as UserRole)}
                >
                  {userRoles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </ListItem>
            )}
          <ListItem></ListItem>
          <ListItem
            component="div"
            sx={{
              pl: '8px',
            }}
          >
            <Tooltip arrow disableHoverListener={open} placement="right" title="Logout">
              <IconButton onClick={() => handleLogout()} color="primary" aria-label="logout-button">
                <Logout fontSize="small" color="inherit" />
              </IconButton>
            </Tooltip>

            <ListItemText
              primary="Logout"
              sx={{ opacity: open ? 1 : 0 }}
              primaryTypographyProps={{
                fontWeight: 500,
                fontSize: '11px',
                ml: 1,
              }}
            />
          </ListItem>
        </div>
      </Stack>
    </>
  );
}
